import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActivityLoader from '../../Components/Common/ActivityLoader';
import AlertError from '../../Components/Common/AlertError';
import AlertSuccess from '../../Components/Common/AlertSuccess';
import TableHeader from '../../Components/Common/TableHeader';
import WarnDelete from '../../Components/Common/WarnDelete';
import PageContainer from '../../Components/Headings/PageContainer';
import LoadMoreButton from '../../Components/NavMenus/LoadMoreButton';
import ReportFilter from '../../Components/Report/ReportFilter';
import PopAssignFeesGroup from '../../Components/SchoolFees/PopAssignFeesGroup';
import PopSyncStudent from '../../Components/SchoolFees/PopSyncStudent';
import SchoolContext from '../../Context/SchoolContext';
import ajaxSchool from '../../remote/ajaxSchool';
import functions from '../../util/functions';
import ButtonGroup from '../../Components/Common/Controls/ButtonGroup';
import StudentFilter from '../../Components/Student/StudentFilter';

class StudentsRecordsPage extends Component {

    static contextType = SchoolContext

    state={
        studentId:false,
        modal:false,
        loading:false,
        queuedStudent:false,
        info:''
    }
   

    onSelectRecord=(studentId)=>{
        this.setState({studentId})
    }

    handleRefresh=()=>{
        this.context.listAllStudents()
    }

    handleSync=()=>{

        this.setState({
            modal:<PopSyncStudent/>
        }, ()=>{functions.openPopup("mdl-sync-student")})

    }


    handleFeesGroup=()=>{
        this.setState({
            modal:<PopAssignFeesGroup studentId={this.state.studentId} liveUpdate={true}/>
        }, ()=>{functions.openPopup("mdl-assign-fees-group")})
    }




    RenderControls=()=>{
        return (
            <div className='float-sm-right'>
                <ol className='button-listing'>

                    <li><button className="btn ripple btn-primary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                        </button>
                    </li>
                   
                    <li>
                        <ButtonGroup title="Options">
                            {this.state.studentId &&
                            <Link to="#" className="dropdown-item" onClick={this.handleFeesGroup}>
                                <i className="fa fa-users mr-1"></i>&nbsp;Fees Group</Link>
                            }
                   
                            <Link to="#" className="dropdown-item" onClick={this.handleSync}>
                            <i className="fa fa-sync-alt mr-1"></i>&nbsp;Sync Students</Link>
     
                            <Link to="#" className="dropdown-item" onClick={this.handleRefresh}>
                            <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</Link>

                          
                        </ButtonGroup>

                    </li>
                 
                </ol>
               
    
                
            </div>
          
        )
      }
    

      handBillStudent=(studentId, key)=>{
            this.setState({
                loading:true,
                queuedStudent:studentId
            }, async()=>{
                const server_reponse = await ajaxSchool.billStudent(studentId);
                this.setState({
                    loading:false
                })
                if(server_reponse.status==="OK")
                {
                    this.context.studentListing[key].billing = server_reponse.details;

                    this.setState({
                        info:<AlertSuccess message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })
                   
                }else{

                    this.setState({
                        info:<AlertError message={server_reponse.message}/>
                    }, ()=>{
                        setTimeout(() => {
                                this.setState({info:''})
                        }, 5000);
                    })

                }
            })
      }
    

     


    render() {

        const {studentListing, studentMeta, loading, goToNextStudentPage} = this.context;

        return (
            <PageContainer 
                title="Students"
                headerNav={<this.RenderControls/>}
                >
                {this.state.modal}
                <div className="row">
                <div className="col-sm-12 col-xl-12 col-lg-12">
                    <StudentFilter/>
                    <div className="card custom-card ">
                            <div className="card-body map-card">

                        <TableHeader
                            title="Student Listing"
                            subtitle="List of all students"
                        />

                        <LoadMoreButton
                          title={studentMeta.current_page + " of " + studentMeta.total_pages}
                          action={goToNextStudentPage}
                          loading={loading}
                        />

                        <div className="user-manager mt-3 scroll-widget border-top overflow-auto">
                          <div className="table-responsive table-ellipsi">
                            <div className="card-body p-0">
                                {this.state.info}
                                    {!studentListing && <ActivityLoader />}
                            {Array.isArray(studentListing) && <table className="table table-bordered text-nowrap mb-0 datatabl" id='table-students'>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Reg NO</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Gender</th>
                                        <th>Class</th>
                                        <th>Residence</th>
                                        <th>Billed Amount</th>
                                        <th>Payment Code</th>
                                        <th>Fees Group</th>
                                        <th>Contact</th>
                                        <th>Last Sync</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentListing.map((item, key) =>

                                        <tr
                                            className={`${item.id === this.state.studentId ? 'flag-row' : 'hot-tr'}`}
                                            style={{ cursor: 'default' }}
                                            key={key}
                                            onClick={() => this.onSelectRecord(item.id, item)}
                                            id={"student" + item.id}
                                        >
                                            <td>{key+1}</td>
                                            <td>
                                            <Link to={
                                                {
                                                    pathname:`/school/fees/students/profile/${item.id}`,
                                                    state:{studentId:item.id}
                                                }
                                                }>
                                                {item.reg_no}
                                                </Link>
                                                </td>
                                            <td>{item.first_name}</td>
                                            <td>{item.last_name}</td>
                                            <td>{item.gender}</td>
                                            <td>{item.class.class_name}</td>
                                            <td>{item.residence.residence_name || <i>Not Assigned</i>}</td>
                                            <td>
                                               <span id={"inbill-" + item.reference_id}>
                                                    {(!this.state.loading || item.id!==this.state.queuedStudent) && <Link to="#" onClick={()=>this.handBillStudent(item.id, key)}>{item.billing.amount_c}</Link>}
                                                    {this.state.loading && item.id===this.state.queuedStudent && <ActivityLoader inline size={20}/>}
                                                </span> 
                                            </td>
                                            <td>{item.payment_code}</td>
                                            <td>
                                                <span id={"fGroup"+item.id}>{item.fees_group.group_name}</span>
                                               
                                            </td>
                                            <td>{item.home_contact}</td>

                                            <td>{item.updated_at.short_date + " " + item.updated_at.time}</td>
                                        </tr>

                                    )}

                                </tbody>
                            </table>}
                        
                        
                            </div>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>
                </div>

                </div>
            </PageContainer>

        );
    }
}

export default StudentsRecordsPage;
