import React, { useEffect, useState } from 'react';
import ajaxSchool from '../remote/ajaxSchool';

const SchoolContext = React.createContext();

export const AuthConsumer = SchoolContext.Consumer;

export const SchoolProvider = (props)=> {

    const [totalInvoices, setTotalInvoices] = useState("...");
    const [batchId, setBatchId] = useState(false);
    const [batch, setBatch] = useState(false);
    const [totalPaid, setTotalPaid] = useState("...");
    const [collectionPercentage, setCollectionPercentage] = useState("...%");
    const [totalBilledStudents, setTotalBilledStudents] = useState("...");
    const [totalStudents, setTotalStudents] = useState("...");
    const [classBreakDown, setClassBreakdown] = useState(false);
    const [feesGroups, setFeesGroups] = useState(false);
    const [recentStudents, setRecentStudents] = useState(false);
    const [feesGraph, setFeesGraph] = useState(false);
    const [graphErrorMessage, setGraphErrorMessage] = useState("");
    const [studentFilter, setStudentFilter] = useState({"page":"1"});
    const [studentListing, setStudentListing] = useState(false);
    const [classListing, setClassListing] = useState(false);
    const [studentMeta, setStudentMeta] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [billingBatches, setBillingBatches] = useState(false);
    const [feesVotes, setFeesVotes] = useState(false);
    const [feesConfigListing, setFeesConfigListing] = useState(false);
    const [feesQuickStat, setFeesQuickStat] = useState(false);
    const [residenceListing, setResidenceListing] = useState(false);


    useEffect(()=>{
       getFeesQuickStat();
       getTotalInvoices();
       getTotalPaid();
       getPaidPercentage();
       getTotalBilledStudents();
       getTotalStudents();
       getCurrentBatch();
       getClassInvoiceBreakdown();
       listFeesGroups();
       listRecentStudents();
       getFeesGraph();
    }, [batchId])

    useEffect(()=>{
        listBillingBatches();
        listAllClasses();
        listFeesVotes();
        listFeesConfig();
        listResidences();
    }, [])

    useEffect(()=>{
        listAllStudents();
    }, [studentFilter])



    useEffect(()=>{
        if(currentPage>1)
        {
            fetchMoreStudentsTrigger()
        }
    }, [currentPage])



   const getTotalInvoices=async()=>{
        const server_response = await ajaxSchool.fetchTotalFeesInvoiced({"batch_id":batchId})
        if(server_response.status==="OK")
        {
            setTotalInvoices(server_response.details.amount_p)
        }
   }

   const getTotalPaid=async()=>{
        const server_response = await ajaxSchool.fetchTotalFeesPaid({"batch_id":batchId})
        if(server_response.status==="OK")
        {
            setTotalPaid(server_response.details.total_p)
        }
    }

    const getPaidPercentage=async()=>{
        const server_response = await ajaxSchool.fetchCollectionPercentage({"batch_id":batchId})
        if(server_response.status==="OK")
        {
            setCollectionPercentage(server_response.details + "%")
        }else{
            setCollectionPercentage(server_response.details + "%")
        }
    }


    const getTotalBilledStudents=async()=>{
        const server_response = await ajaxSchool.fetchTotalStudentsBilled({"batch_id":batchId})
        if(server_response.status==="OK")
        {
            setTotalBilledStudents(server_response.details.total_p)
        }
    }

    const getTotalStudents=async()=>{
        const server_response = await ajaxSchool.fetchTotalStudentsPopulation({"batch_id":batchId})
        if(server_response.status==="OK")
        {
            setTotalStudents(server_response.details.total_p)
        }
    }

   const getCurrentBatch=async()=>{
        const server_response = await ajaxSchool.getCurrentBatch();
        if(server_response.status==="OK")
        {
            setBatch(server_response.details);
            setBatchId(server_response.details.id)
        }
   }

   const getClassInvoiceBreakdown=async()=>{
        const server_response = await ajaxSchool.getClassInvoiceBreakdown(batchId);
        if(server_response.status==="OK")
        {
            setClassBreakdown(server_response.details);
        }else{
            setClassBreakdown("404");
        }
  }


  const listFeesGroups=async()=>{
    const server_response = await ajaxSchool.fetchFeesGroupStructure();
    if(server_response.status==="OK")
    {
        setFeesGroups(server_response.details);
    }else{
        setFeesGroups("404");
    }
  }


  const listRecentStudents=async()=>{
    const server_response = await ajaxSchool.fetchRecentStudents();
    if(server_response.status==="OK")
    {
        setRecentStudents(server_response.details);
    }else{
        setRecentStudents("404");
    }
  }

  const getFeesGraph=async()=>{
    const server_response = await ajaxSchool.getFeesGraph({"batch_id":batchId});
    if(server_response.status==="OK")
    {
        setFeesGraph(server_response.details);
    }else{
        setFeesGraph("404");
        setGraphErrorMessage(server_response.message)
    }
  }

  const listAllStudents=async()=>{
    setStudentListing(false)
    // setStudentFilter({"page":"1"})
    // setCurrentPage(1);
    const server_response = await ajaxSchool.fetchAllStudents(studentFilter);
    if(server_response.status==="OK")
    {
        setStudentListing(server_response.details.list);
        setStudentMeta(server_response.details.meta)
    }else{
        setStudentListing("404");
    }
  }


  const listAllClasses=async()=>{
    const server_response = await ajaxSchool.fetchAllClasses();
    if(server_response.status==="OK")
    {
        setClassListing(server_response.details);
    }else{
        setClassListing("404");
    }
  }



  const listBillingBatches=async()=>{
    setBillingBatches(false)
    const server_response = await ajaxSchool.fetchBiillingBatches();
    if(server_response.status==="OK")
    {
        setBillingBatches(server_response.details);
    }else{
        setBillingBatches("404");
    }
  }






  const fetchMoreStudentsTrigger=()=>{
    const newData={
        ...studentFilter,
        "page":currentPage,
    }
    fetchMoreStudents(newData)
}


const fetchMoreStudents = async(newData) => {
    setLoading(true);
    const server_response = await ajaxSchool.fetchAllStudents(newData);

    setLoading(false);
       
    if (server_response.status === "OK") {
        const incoming = Array.from(server_response.details.list);
        setStudentListing(studentListing.concat(incoming));
        setStudentMeta(server_response.details.meta);
    }
   
}


const goToNextStudentPage=()=>{
    if(currentPage < studentMeta.total_pages){
        setCurrentPage(currentPage + 1);
    }
   
}


const resetData=()=>{
    setCurrentPage(1)
    setStudentFilter({"page":"1"})
}



const listFeesVotes=async()=>{
    setFeesVotes(false)
    const server_response = await ajaxSchool.fetchFeesVotes();
    if(server_response.status==="OK")
    {
        setFeesVotes(server_response.details);
    }else{
        setFeesVotes("404");
    }
  }



  const listFeesConfig=async()=>{

    const server_response = await ajaxSchool.fetchFeesConfig();
    if(server_response.status==="OK")
    {
        setFeesConfigListing(server_response.details);
    }else{
        setFeesConfigListing("404");
    }
  }




  const getFeesQuickStat=async()=>{
    const server_response = await ajaxSchool.fetchQuickStat({"batch_id":batchId})
    if(server_response.status==="OK")
    {
        setFeesQuickStat(server_response.details)
    }
  }


  const listResidences=async()=>{
    setResidenceListing(false)
    const server_response = await ajaxSchool.fetchResidences();
    if(server_response.status==="OK")
    {
        setResidenceListing(server_response.details);
    }else{
        setResidenceListing("404");
    }
  }


const filterStudent=(options)=>{
    setStudentFilter({
        "student_class":options.studentClass,
        "student_residence":options.studentResidence,
        "page":"1",
        "billing_status": options.billingStatus,
        "reg_no": options.studentReg
    })
    
}


    
    return (
           <SchoolContext.Provider value={
               {
                  totalInvoices,
                  batch,
                  totalPaid,
                  collectionPercentage,
                  totalBilledStudents,
                  totalStudents,
                  classBreakDown,
                  feesGroups,
                  recentStudents,
                  feesGraph,
                  graphErrorMessage,
                  studentListing,
                  classListing,
                  studentMeta,
                  loading,
                  billingBatches,
                  feesVotes,
                  feesConfigListing,
                  feesQuickStat,
                  residenceListing,
                  setBatchId,
                  getTotalInvoices,
                  getTotalPaid,
                  listFeesGroups,
                  listRecentStudents,
                  getFeesGraph,
                  listAllStudents,
                  listAllClasses,
                  goToNextStudentPage,
                  listBillingBatches,
                  listFeesVotes,
                  listFeesConfig,
                  listResidences,
                  filterStudent,
                  resetData
               }
               }>
               {props.children}
           </SchoolContext.Provider>
        );
    
}

export default SchoolContext;