import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import FilterSelectInput from '../../Components/Common/Filter/FilterSelectInput'
import FilterTextInput from '../../Components/Common/Filter/FilterTextInput'
import ScrollFilterBar from '../../Components/Common/Filter/ScrollFilterBar'
import PageContainer from '../../Components/Headings/PageContainer'
import SchoolDefaulterContext from '../../Context/SchoolDefaulterContext'
import SchoolContext from '../../Context/SchoolContext'
import dictionary from '../../util/dictionary'
import LoadMoreButton from '../../Components/NavMenus/LoadMoreButton'

const FeesDefaultersPage=(props)=> {

  const { transactionListing, resetData, filterData, getSchoolDefaulterTransactions, metaData, goToNextPage, loading } = useContext(SchoolDefaulterContext)
  const { classListing,  residenceListing} = useContext(SchoolContext)

  const [transactionId, setTransactionId] = useState('');
  const [currentRecord, setCurrentRecord] = useState('');
  

  const onSelectRecord = (transactionId, currentRecord) => {

    setTransactionId(transactionId);
    setCurrentRecord(currentRecord)
  }

 
  const RenderTransactions = () => {
    return (
      <>
        {!transactionListing && <ActivityLoader />}

        <div className="table-responsive table-ellipsis">
          {Array.isArray(transactionListing) &&
            <table id="table-fees-defaulters" className='table table-hover text-nowrap mg-b-0 datatable' export-format={dictionary._exportBtnFormats}>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Reg No.</th>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Residence</th>
                  <th>Total Balance</th>
                  <th>Home Contact</th>
                </tr>
              </thead>
              <tbody>
                {transactionListing.map((item, key) => (
                  <tr
                    className={`${
                      item.student.id === transactionId
                        ? 'flag-row'
                        : 'hot-tr'
                    }`}
                    style={{ cursor: 'default' }}
                    key={key}
                    onClick={() => onSelectRecord(item.student.id, item)}
                  >
                    <td>{key + 1}</td>
                    <td>
                    <Link to={
                                  {
                                      pathname:`/school/fees/students/profile/${item.student.id}`,
                                      state:{studentId:item.id}
                                  }
                                  }>
                      {item.student.reg_no}
                      </Link>
                    </td>
                    <td>{item.student.last_name + " " + item.student.first_name}</td>
                    <td>{item.student.class.class_name}</td>
                    <td>{item.student.residence.residence_name}</td>
                    <td>{item.balance.amount_p}</td>
                    <td>{item.student.home_contact}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </>
    )
  }

 



  const RenderFilter = () => {
          
    const optionsclassList=[{title:"All Classes", value:"0"}];
    if(Array.isArray(classListing))
    {
        classListing.forEach((item)=>
            optionsclassList.push({title:item.class_name, value:item.reference_id})
        )
    }

    const optionsResidence=[{title:"All Residences", value:"0"}]
    if(Array.isArray(residenceListing)){
      residenceListing.forEach(item =>{
        optionsResidence.push({title:item.residence_name, value:item.reference_id})
      })
    }

    return (
        <ScrollFilterBar
            onReset={resetData}
            onConfirm={filterData}
            firstScreen={
              {
                inputs:[
                 (onSet, key)=> <FilterTextInput 
                    key={key}
                    label="Amount Criteria"
                    placeholder="Enter amount >=" 
                    onChange={onSet}
                    name="amountCriteria"
                    type="number"
                    />,
                    (onSet, key)=><FilterSelectInput
                      key={key}
                      label="Student Class"
                      options={optionsclassList}
                      onChange={onSet}
                      name="studentClass"
                    />,
                    (onSet, key)=><FilterSelectInput
                    key={key}
                    label="Student Residence"
                    options={optionsResidence}
                    onChange={onSet}
                    name="studentResidence"
                  />
                ],
                names:["amountCriteria", "studentClass", "studentResidence"]
              }
            }
        />
    )

    
}


const Buttons = () => {
  return (
      <>
        <ol className='button-listing'>
               <li><button className="btn ripple btn-primary navresponsive-toggler" data-toggle="collapse" data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="fa fa-filter mr-1"></i>  Filter <i className="fas fa-caret-down ml-1"></i>
                  </button>
                </li>
               
                <li>
                    <button className="btn ripple btn-secondary mb-1" onClick={getSchoolDefaulterTransactions}>
                        <i className="fa fa-redo mr-1"></i>&nbsp;Refresh</button>
                </li>
            </ol>
          
         
          

      </>
  )
}
 
    return (
      <PageContainer
         title='Fees Defaulters'
         headerNav={Buttons()}
         >
        <div className='row'>
          <div className='col-md-12'>

          {RenderFilter()}

            <div className='card'>
              <div className='card-header border-transparent'>
                <h3 className='card-title'>All fees defaulters Listing</h3>
              </div>

             

              <div className='card-body'>
              <LoadMoreButton
                          title={metaData.current_page + " of " + metaData.total_pages}
                          action={goToNextPage}
                          loading={loading}
                        />
                { RenderTransactions() }
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    )
  }


export default FeesDefaultersPage
